.help-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.2s;
    z-index: 1050;
  }
  
  .help-button:hover {
    background-color: #555;
    transform: scale(1.1);
  }
  
  /* Adjustments for small screens */
  @media (max-width: 600px) {
    .help-button {
      bottom: 10px;
      right: 10px;
      padding: 8px 16px;
      font-size: 14px;
    }
  }
  