/* Main contact section container */.contact-section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh; /* Minimum height for the section */
  background-color: #e0f5fd; /* Light blue background */
  padding: 20px; /* Padding inside the section */
  margin-top: 40px; /* Space above the section */
}

.contact-container {
  display: flex;
  max-width: 1000px;
  width: 100%;
  background-color: transparent; /* Remove background */
  border-radius: 10px;
  overflow: hidden;
  flex-wrap: wrap; /* Allows elements to wrap in smaller screens */
}


.contact-info {
  flex: 1;
  padding: 40px;
  color: #4794cc; /* Ensure text color is white */
}

.info-header {
  font-size: 48px;
  font-weight: 700;
}

.info-subtext {
  font-size: 18px;
  margin-bottom: 10px;
}

.form-wrapper {
  flex: 1;
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit-btn {
  width: 100%;
  padding: 12px;
  background-color: #4794cc; /* Purple button */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #85baff; /* Darker purple on hover */
}

.file-upload-label {
  display: inline-block; /* Allows for padding and background */
  padding: 10px 20px; /* Padding for the button */
  background-color: #007bff; /* Blue background */
  color: white; /* White text color */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 14px; /* Adjust font size as needed */
  text-align: center; /* Center the text */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

/* Change background color on hover */
.file-upload-label:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Optional: Change background color when a file is selected */
.file-input:focus + .file-upload-label,
.file-input:valid + .file-upload-label {
  background-color: #28a745; /* Green background for confirmation */
}
.form-group {
  margin-bottom: 20px; /* Space between form fields */
}
.file-input {
  display: none; /* Hide the actual file input */
}
