/* Flexbox layout for horizontal cards */
.service-card-container {
  display: flex;
  justify-content: flex-start; /* Align cards to the left */
  gap: 15px; /* Even spacing between cards */
  overflow-x: auto; /* Allow horizontal scrolling */
  padding: 10px 15px; /* Add padding to the section */
  scroll-snap-type: x mandatory; /* Enable smooth snap scrolling */
}

/* Individual card styling */
.service-card-container .col-md-3 {
  flex: 0 0 23%; /* Ensure each card takes 23% width */
  min-width: 180px; /* Maintain a minimum width */
  margin-right: 10px; /* Add space between cards */
  scroll-snap-align: start; /* Snap to the start of the container */
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1); /* Subtle shadow for depth */
  transition: transform 0.2s, box-shadow 0.2s; /* Smooth hover effects */
}

/* Remove margin from the last card */
.service-card-container .col-md-3:last-child {
  margin-right: 0;
}

/* Custom scrollbar styling */
.service-card-container::-webkit-scrollbar {
  height: 8px;
}

.service-card-container::-webkit-scrollbar-thumb {
  background-color: rgba(237, 234, 234, 0.5);
  border-radius: 10px;
}

/* Card content */
.card {
  padding: 10px; /* Adjust padding for a compact look */
}

.card:hover {
  transform: scale(1.05); /* Zoom effect on hover */
  box-shadow: 0 8px 16px white; /* Enhanced shadow */
}

/* Custom Button Styling */
.btn-custom {
  background-color: transparent; /* No background */
  color: #4794cc; /* Blue text */
  border: 2px solid #4794cc; /* Blue border */
  padding: 10px 20px; /* Adjust padding */
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  transition: none; /* Disable hover transitions */
}

/* No hover effect */
.btn-custom:hover {
  background-color: transparent; /* No hover background */
  color: #4794cc; /* Maintain blue text */
  border-color: #4794cc; /* Maintain blue border */
}

/* Optional: Rotate icon animation */
.rotate {
  transform: rotate(180deg); /* Rotate icon when expanded */
  transition: transform 0.3s ease;
}

/* Adjust button for larger screens */
@media (min-width: 768px) {
  .btn-custom {
    width: auto; /* Adjust width on larger screens */
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .service-card-container {
    flex-wrap: nowrap; /* Prevent wrapping */
  }

  .col-md-3 {
    flex: 0 0 48%; /* Adjust card size for tablets */
  }
}

@media (max-width: 480px) {
  .col-md-3 {
    flex: 0 0 100%; /* Full width on mobile screens */
  }

  .service-card-container {
    padding: 5px; /* Reduce padding on mobile */
  }

  .btn-custom {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}

