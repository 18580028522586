/* Main contact section container */.contact-section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh; /* Minimum height for the section */
  background-color: #90defa; /* Light blue background */
  padding: 20px; /* Padding inside the section */
  margin-top: 40px; /* Space above the section */
}

/* Additional styles for the contact container, form, etc. can go here */

/* Container for both the text and the form */
.contact-container {
  display: flex;
  max-width: 1000px;
  width: 100%;
  background-color: transparent; /* Remove background */
  border-radius: 10px;
  overflow: hidden;
  flex-wrap: wrap; /* Allows elements to wrap in smaller screens */
}

/* Left side: Text info */
.contact-info {
  flex: 1;
  padding: 10px;
  background-color: transparent;
}

.info-header {
  font-size: 48px;
  font-weight: 700;
  color: #4794cc;
  margin-bottom: 20px;
}

.info-subtext {
  font-size: 18px;
  color: #4794cc;
  margin-bottom: 10px;
}

/* Right side: Contact form */
.form-container {
  flex: 1;
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.form-field {
  margin-bottom: 20px;
}

.form-field label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: #333;
}

.form-field input,
.form-field textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit-button {
  width: 100%;
  padding: 12px;
  background-color:#4794cc; /* Purple button */
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #85baff;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Mobile view: Make elements stack vertically */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column; /* Stack text and form vertically */
  }

  .contact-info {
    text-align: center; /* Center text on mobile */
    padding: 20px;
  }

  .info-header {
    font-size: 32px; /* Reduce font size for smaller screens */
  }

  .info-subtext {
    font-size: 16px; /* Reduce subtext size */
  }

  .form-container {
    padding: 20px; /* Reduce padding for form on mobile */
  }

  .form-field input,
  .form-field textarea {
    font-size: 14px; /* Slightly smaller input font on mobile */
  }

  .submit-button {
    font-size: 14px; /* Slightly smaller button text on mobile */
  }
}

/* Very small screens (like mobile phones) */
@media (max-width: 480px) {
  .info-header {
    font-size: 24px;
  }

  .info-subtext {
    font-size: 14px;
  }

  .form-container {
    padding: 15px;
  }
}
.contact-link {
  color: #4794cc; /* Make the email link white */
  text-decoration: none; /* Remove underline */
}


.contact-link:hover {
  text-decoration: underline; /* Add underline on hover */
}
.contact-link {
  color: #4794cc; /* Ensures the links are white */
  text-decoration: none; /* Remove underline */
}

.contact-link:hover {
  text-decoration: underline; /* Add underline on hover */
}

.fas {
  margin-right: 8px; /* Adds spacing between the icon and the text */
  color: #4794cc; /* Makes the icons white */
}


