.my-5 {
  margin: 0; /* Remove margin to eliminate space */
  padding-top: 0; /* Ensure there's no padding on top */
}

h1 {
  margin-bottom: 0; /* Ensure no bottom margin for the heading */
}

.app {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  flex-wrap: wrap;
  margin-top: 0; /* Ensure no extra top margin */
}

.content {
  flex: 1;
  padding: 0 110px;
  margin: 0; /* Remove margin to eliminate space between items */
}

.mockup {
  flex: 1;
  text-align: center;
  margin: 0; /* Remove margin to eliminate space between items */
}

.mockup img {
  max-width: 80%;
  height: auto;
}

.download-buttons {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap; /* Ensures buttons wrap without a gap */
  gap: 10px; /* Add gap between buttons */
}

.download-buttons a {
  margin-right: 0; /* Remove margin to eliminate space */
}

.download-buttons img {
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 5px;
  width: 150px; /* Set a fixed width for download images */
  height: auto; /* Maintain aspect ratio */
}

@media (max-width: 768px) {
  .app {
    flex-direction: column;
    text-align: center;
    padding: 0;
  }

  .content {
    margin-bottom: 80px;
  }

  .download-buttons {
    flex-direction: column;
    gap: 10px; /* Add gap between download buttons when stacked */
  }

  .mockup {
    order: -1;
  }
}
/* General Container Styles */
.main-container {
  max-width: 1500px;
  margin: 0 auto;
  background-color: #e0f5fd; /* Sky blue background */
  padding: 35px;
  border-radius: 15px;
  box-sizing: border-box; /* Ensure padding doesn’t overflow */
  width: 100%; /* Ensure it takes full width */
}

/* Text Box Styles */
.text-box {
  position: relative;
  background-color: rgba(255, 255, 255, 0.9); /* Transparent white */
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  margin: 50px auto;
  text-align: center;
  z-index: 1;
}

/* Heading and Paragraph Styles */
.text-box h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.text-box p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 10px;
}

/* Carousel Styles */
.carousel-container {
  height: 280px;
  overflow: hidden;
  border-radius: 15px;
  background-color: #e0f5fd;
  position: relative;
  width: 100%; /* Ensure carousel fits screen width */
}

/* Carousel Images */
.transparent-carousel img {
  width: 100%;
  height: 110%;
  object-fit: cover; /* Ensure full image covers the area */
  display: block;
}

/* Responsive Styles for Medium Screens */
@media (max-width: 768px) {
  .main-container {
    padding: 15px;
    
  }

  .carousel-container {
    height: 260px; /* Adjust height for tablets */
  }

  .text-box {
    padding: 30px;
  }

  .text-box h2 {
    font-size: 1.5rem; /* Adjust title size */
  }

  .text-box p {
    font-size: 0.5rem;
  }
}

/* Extra Small Devices (Phones) */
@media (max-width: 576px) {
  .main-container {
    padding: 20px;
  }

  .carousel-container {
    height: 170px; /* Adjust height for small screens */
  }

  .transparent-carousel img {
    object-fit: contain; /* Maintain aspect ratio without cropping */
  }

  .text-box {
    padding: 10px;
  }

  .text-box h2 {
    font-size: 1.2rem; /* Reduce font size for phones */
    margin-bottom: 15px;
  }

  .text-box p {
    font-size: 0.9rem;
    line-height: 1.5;
  }
}

/* Very Small Devices (Below 400px) */
@media (max-width: 400px) {
  .main-container {
    padding: 15px;
  }

  .carousel-container {
    height: 150px; /* Smaller height for tiny screens */
  }

  .text-box {
    padding: 10px;
  }

  .text-box h2 {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .text-box p {
    font-size: 0.85rem;
    line-height: 1.4;
  }
}

/* Landscape Mobile Screens */
@media (max-width: 820px) and (orientation: landscape) {
  .carousel-container {
    height: 180px;
  }

  .text-box h2 {
    font-size: 1.3rem;
  }

  .text-box p {
    font-size: 0.95rem;
  }
}

/* Large Screens (Above 1200px) */
@media (min-width: 1200px) {
  .text-box h2 {
    font-size: 2.2rem;
  }

  .text-box p {
    font-size: 1.0rem;
  }
}



/* Carousel Indicators */
.carousel-indicators {
  position: absolute;
  bottom: 10px; /* Position at the bottom of the carousel */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px; /* Spacing between dots */
  z-index: 2;
}

/* Individual Dot */
.carousel-indicators li {
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  border-radius: 50%; /* Make it circular */
  transition: background-color 0.3s ease;
  cursor: pointer;
}

/* Active Dot */
.carousel-indicators .active {
  background-color: #fff; /* White color for the active dot */
}

/* Adjust Dot Size for Smaller Screens */
@media (max-width: 576px) {
  .carousel-indicators li {
    width: 8px;
    height: 8px;
  }
}
