/* Card container with smooth transitions */
.card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 8px;
    overflow: hidden; /* Ensures content stays within the card */
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Initial shadow */
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensure cards stretch evenly */
}

.card:hover {
    transform: scale(1.05); /* Slight zoom effect */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

/* Card body: Ensure content flows evenly within the card */
.card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space between text and button */
    padding: 15px;
    height: 50%; /* Full height to maintain consistency */
}

/* Container for text and button */
.text-container {
    flex-grow: 1; /* Make text take up available space */
}

/* Center the button and align it to the bottom */
.text-button-container {
    display: flex;
    justify-content: center;
    margin-top: auto; /* Push button to the bottom */
}

/* Custom button styles */
.btn-custom {
    background-color: #0077b6; /* Initial button color */
    color: white;
    padding: 12px 24px;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    width: 100%; /* Button is full width on mobile */
}

.btn-custom:hover {
    background-color: #00aeef; /* Hover color */
    transform: scale(1.05); /* Slight zoom on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .card {
        height: auto; /* Allow flexible height on smaller screens */
    }

    .btn-custom {
        font-size: 13px; /* Adjust button size for smaller screens */
        padding: 10px 20px;
    }
}

@media (max-width: 480px) {
    .btn-custom {
        font-size: 12px; /* Further reduce font size on very small screens */
    }
}
