/* General Carousel Styling */
.carousel, 
.carousel-item {
  height: 60vh;
  margin-bottom: 0;
  padding-bottom: 0;
}

.carousel-item img {
  width: 100%;
  height: auto;
  max-height: 90vh;
  object-fit: cover;
}

/* Carousel Button Styling */
.carousel-button {
  background-color: #4794cc; /* Change this to your preferred color */
  color: white; /* Text color */
  border: none;
  padding: 10px 20px; /* Padding for the button */
  font-size: 16px; /* Font size */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s; /* Transition for hover effect */
}

.carousel-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
  transform: scale(1.05); /* Slightly grow on hover */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .carousel-button {
    width: 30%; /* Full width on smaller screens */
    font-size: 14px; /* Smaller font size */
  }
}


/* Carousel Caption Alignment */
.carousel-caption {
  text-align: left;
  left: 10%;
  bottom: 20%;
  transform: translateY(0);
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .carousel, .carousel-item {
    height: 40vh; /* Adjust height for medium devices */
  }

  .carousel-caption {
    left: 8%;
    bottom: 15%;
  }

  .carousel-button {
    font-size: 5px;
    padding: 8px 12px;
    margin-left: 8px;
  }
}

@media (max-width: 576px) {
  .carousel, .carousel-item {
    height: 20vh; /* Set a lower height for mobile */
  }

  .carousel-caption {
    left: 5%;
    bottom: 10%;
  }

  .carousel-button {
    font-size: 10px; /* Adjust font size for better visibility */
    padding: 2px 4px; /* Adjust padding for smaller button */
    margin-left: 5px;
    position: absolute;
    bottom: 40px; /* Position the button closer to the bottom */
    left: 5%; /* Adjust positioning */
    right: 60%; /* Center the button horizontally */
    background-color: #007bff; /* Semi-transparent background */
    border: none; /* Remove border for a cleaner look */
  }

  .carousel-button:hover {
    background-color: rgba(255, 255, 255, 0.8); /* Adjust hover effect */
  }
}

/* Mission Section Styling */
.mission-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  margin: 10px auto;
  text-align: center;
  max-width: 1200px;
}

.mission-text {
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.mission-text h2 {
  font-size: 32px;
  margin-bottom: 15px;
  font-weight: 700;
}

.mission-text p {
  font-size: 18px;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .mission-section {
    padding: 15px;
  }

  .mission-text {
    padding: 20px;
  }

  .mission-text h2 {
    font-size: 24px;
  }

  .mission-text p {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .mission-section {
    padding: 10px;
    margin: 10px auto 0; /* Remove bottom margin */
  }

  .mission-text h2 {
    font-size: 22px;
  }

  .mission-text p {
    font-size: 12px;
  }
}

/* Why Choose Us Section */
.why-choose-us-section {
  text-align: center;
  padding: 40px;
  background-color: #f9f9f9;
}

.section-title {
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: bold;
  color: var(--text-color);
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.card {
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

.card-title {
  font-size: 20px;
  color: var(--primary-color);
  font-weight: 600;
}

.card-description {
  font-size: 16px;
}

@media (max-width: 768px) {
  .why-choose-us-section {
    padding: 20px;
  }

  .section-title {
    font-size: 28px;
  }

  .card-title {
    font-size: 18px;
  }

  .card-description {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .why-choose-us-section {
    padding: 15px; /* Adjust padding */
  }

  .section-title {
    font-size: 24px; /* Reduce title size */
  }

  .card-title {
    font-size: 16px; /* Adjust card title */
  }

  .card-description {
    font-size: 12px; /* Adjust card description */
  }
}
