.navbar-container {
  width: 100%; /* Ensure the navbar takes full width */
}

.custom-navbar {
  padding: 20px 140px; /* Adjust padding as needed */
  background-color: #ffffff !important; /* Sky blue color */
  border-bottom: 2px solid #ececec; /* Border color and style */
}

.navbar-logo {
  display: flex;
  align-items: center; /* Center the logo vertically */
  margin-right: auto; /* Push nav items to the right */
}

.logo {
  width: 100%; /* Make logo responsive */
  max-width: 200px; /* Set maximum width to prevent it from being too large */
  height: auto; /* Maintain aspect ratio */
}

/* Mobile (up to 767px) */
@media (max-width: 767px) {
  .navbar-logo {
      margin-right: 0; /* Reset margin on mobile for better alignment */
      justify-content: center; /* Center the logo on mobile */
  }

  .custom-navbar {
      padding: 15px 20px; /* Adjust padding for smaller screens */
  }

  .logo {
      max-width: 150px; /* Reduce logo size for mobile */
  }
}

/* Tablets (768px to 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .custom-navbar {
      padding: 20px 40px; /* Adjust padding for tablets */
  }

  .logo {
      max-width: 180px; /* Adjust logo size for tablets */
  }
}

/* Small laptops (992px to 1199px) */
@media (min-width: 992px) and (max-width: 1199px) {
  .custom-navbar {
      padding: 20px 80px; /* Adjust padding for small laptops */
  }

  .logo {
      max-width: 200px; /* Logo size for laptops */
  }
}

/* Large desktops (1200px and above) */
@media (min-width: 1200px) {
  .custom-navbar {
      padding: 20px 140px; /* Maintain padding for large desktops */
  }

  .logo {
      max-width: 200px; /* Maintain logo size for large screens */
  }
}
  /* Navbar styles */
  .custom-navbar .nav-link {
    color: black; /* Make the navigation links black */
    font-weight: bold;
  }
  
  .custom-navbar .nav-link:hover {
    color: #000000; /* Change this to the color you want on hover (e.g., blue) */
  }
  
  /* Adjust the active link color */
  .custom-navbar .nav-link.active {
    color: #000000; /* Active link color (e.g., orange) */
  }
  
  /* For better spacing between navbar items */
  .custom-navbar .nav-link {
    margin-right: 20px;
  }
  /* Navbar styles */
.custom-navbar .nav-link {
  color: black; /* Make the navigation links black */
  font-weight: bold;
}

/* Change link color on hover */
.custom-navbar .nav-link:hover {
  color: #000000; /* Change this to the color you want on hover */
}

/* Adjust the active link color */
.custom-navbar .nav-link.active {
  color: #000000; /* Active link color */
}

/* For better spacing between navbar items */
.custom-navbar .nav-link {
  margin-right: 20px; /* Spacing between items */
}

/* Center text in mobile view */
@media (max-width: 768px) {
  .custom-navbar .navbar-collapse {
    text-align: center; /* Center-align the text */
  }

  .custom-navbar .nav {
    display: flex;
    flex-direction: column; /* Stack links vertically */
    align-items: center; /* Center links horizontally */
    width: 100%; /* Ensure it takes the full width */
  }

  .custom-navbar .nav-item {
    width: 100%; /* Make each nav item full-width */
    margin: 0; /* Remove margin to ensure proper centering */
  }

  .custom-navbar .nav-link {
    width: 100%; /* Make each nav link full-width */
    padding: 10px; /* Add some padding for clickable area */
    margin-right: 0; /* Remove right margin in mobile view */
  }
}
