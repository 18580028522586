/* Footer Styles */
.footer-dark {
  padding: 50px 0;
  color: white; /* Text color set to white */
  background-color: #000;
  ; /* Background color */
}
.contact-icon {
  margin-right: 10px;
  font-size: 15px;
  color: #fff; /* White icon color */
}
/* Ensure the entire contact section text is white */
.col-md-6.item.text {
  color: white;
}

/* Make the icons white */
.icon-white {
  color: white;
  margin-right: 8px; /* Adjust spacing between icon and text */
}

/* Ensure the h3 header is also white */
.col-md-6.item.text h3 {
  color: white;
  margin-bottom: 10px; /* Adjust spacing below header */
}

.footer-dark h3 {
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 16px;
  color: white; /* Text color set to white */
}

.footer-dark ul {
  padding: 0;
  list-style: none;
  line-height: 1.6;
  font-size: 17px;
}

.footer-dark ul a {
  color: white; /* Link color set to white */
  text-decoration: none;
  opacity: 0.8;
}

.footer-dark ul a:hover {
  opacity: 1;
}

.footer-dark .item.text p {
  opacity: 0.8;
  font-size: 14px;
  color: white; /* Paragraph text color set to white */
}

.footer-dark .footer-bottom {
  padding-top: 20px;
  padding-bottom: 10px;
  border-top: 1px solid #ffffff;
}

.footer-dark .footer-bottom p {
  margin: 0;
  font-size: 15px;
  color: white; /* Footer-bottom text color set to white */
}

/* Responsive Design */
@media (max-width: 767px) {
  .footer-dark .item {
      text-align: center;
      padding-bottom: 20px;
  }

  .footer-dark .item.text {
      margin-bottom: 30px;
  }

  .footer-dark ul {
      text-align: center;
  }
}
/* Style for the information wrapper */
.information-wrapper {
  margin-top: 15px; /* Add space above the information items */
}

/* Style for each information item */
.information-item {
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
  margin-bottom: 10px; /* Space between each item */
  color: white; /* Set text color */
}

/* Style for icons */
.information-item i {
  font-size: 20px; /* Adjust icon size */
  margin-right: 10px; /* Space between icon and text */
  color: #ffffff; /* Set icon color (you can change this to any color you like) */
}

/* Optional: Add hover effect for icons */
.information-item i:hover {
  color: #2980b9; /* Change color on hover */
  transition: color 0.3s; /* Smooth transition for hover effect */
}
