h1,
h2,
h3,
h4,
h5,
h6 {}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
}

a,
a:active,
a:focus {
    color: #6f6f6f;
    text-decoration: none;
    transition-timing-function: ease-in-out;
    transition-duration: .2s;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

img {
    max-width: 100%;
    height: auto;
}

section {
    padding: 60px 0;
}

.sec-title {
    position: relative;
    z-index: 1;
    margin-bottom: 60px;
}

.sec-title .title {
    position: relative;
    display: block;
    font-size: 18px;
    line-height: 24px;
    color: #00aeef;
    font-weight: 500;
    margin-bottom: 15px;
}

.sec-title h2 {
    position: relative;
    display: block;
    font-size: 40px;
    line-height: 1.28em;
    color: #222222;
    font-weight: 600;
    padding-bottom: 18px;
}

.sec-title h2:before {
    position: absolute;
    content: '';
    left: 0px;
    bottom: 0px;
    width: 50px;
    height: 3px;
    background-color: #d1d2d6;
}

.sec-title .text {
    position: relative;
    font-size: 16px;
    line-height: 26px;
    color: #848484;
    font-weight: 400;
    margin-top: 35px;
}

.sec-title.light h2 {
    color: #ffffff;
}

.sec-title.text-center h2:before {
    left: 50%;
    margin-left: -25px;
}

.list-style-one {
    position: relative;
}

.list-style-one li {
    position: relative;
    font-size: 16px;
    line-height: 26px;
    color: #222222;
    font-weight: 400;
    padding-left: 35px;
    margin-bottom: 12px;
}

.list-style-one li:before {
    content: "\f058";
    position: absolute;
    left: 0;
    top: 0px;
    display: block;
    font-size: 18px;
    padding: 0px;
    color: #ff2222;
    font-weight: 600;
    font-family: "Font Awesome 5 Free";
}

.list-style-one li a:hover {
    color: #44bce2;
}

.btn-style-one {
    position: relative;
    display: inline-block;
    font-size: 17px;
    line-height: 20px;
    color: #ffffff;
    padding: 10px 30px;
    border-radius: 5px;
    font-weight: 600;
    overflow: hidden;
    letter-spacing: 0.02em;
    background-color:  #4794cc;
}

.btn-style-one:hover {
    background-color: #00aeef;
    color: #ffffff;
}

.about-section {
    position: relative;
    padding: 40px 0 40px; /* Reduced top padding */
}

.about-section .sec-title {
    margin-bottom: 45px;
}

.about-section .content-column {
    position: relative;
    margin-bottom: 50px;
}

.about-section .content-column .inner-column {
    position: relative;
    padding-left: 10px; /* Further reduced padding for inner column */
}

.about-section .text {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 26px;
    color: #848484;
    font-weight: 400;
}

.about-section .list-style-one {
    margin-bottom: 45px;
}

.about-section .btn-box {
    position: relative;
}

.about-section .btn-box a {
    padding: 15px 50px;
}

.about-section .image-column {
    position: relative;
    max-width: 70%; /* Further reduced column width */
    margin: 0 auto; /* Center column */
}

.about-section .image-column .text-layer {
    position: absolute;
    right: -80px; /* Adjusted position */
    top: 50%;
    font-size: 250px; /* Reduced font size */
    line-height: 1em;
    color: #ffffff;
    margin-top: -125px; /* Adjusted margin */
    font-weight: 500;
}

.about-section .image-column .image-1 img,
.about-section .image-column .image-2 img {
    border-radius: 40px; /* Adjusted border radius */
    max-width: 80%; /* Reduced max width of images */
    height: auto; /* Maintain aspect ratio */
}

/* Removed box-shadow from images */
.about-section .image-column .image-1 img,
.about-section .image-column .image-2 img {
    box-shadow: none; /* No shadow */
}

/* Navbar adjustments */
.navbar {  /* Replace with your actual navbar class */
    margin-bottom: 1; /* Remove bottom margin */
    padding-bottom: 0; /* Remove bottom padding, if any */
}
